import logo from './ian-morrison.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blog from "./pages/Blog";
import Container from '@mui/material/Container';
import React from 'react';
import './App.css';
import Paths from './Routes';

function App() {
  return (
    /**
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
    */
    /**
    <div className="App">
      <Paths />
    </div> */
    <div className="App">
      <Blog />
    </div>
  );
}

/**
 *      <iframe
          src="build/web/index.html"
          width="640"
          height="480"
          title="PyGame"
          style={{ border: 'none' }}
        ></iframe>
 */

export default App;
